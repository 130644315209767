import "./App.css";
import "./NFTstyles.css";
import { useState, useEffect } from "react";
import NFTCard from "./components/NFTCard";
import librePlanetGroupChatPhoto from "./imgs/assets/libre-planet-group-photo-png.png";

function App() {
  const [assets, setAssets] = useState([]);

  const get_assets_without_api = () => {
    /* Asset Object structure: 
    token_id
    image_url
    background_color
    name
    external_link
    asset_contract
    owner
    traits
    last_sale 
    */
    const fakeAssetObject = {
      token_id:
        "78841194175776325081494317283180106410278629423018831166144717576454623199233",
      image_url: "http://librenfts.xyz/images/sea-nft.jpg",
      background_color: "#fff",
      description: "An ape made by Pat. A birthday gift",
      name: "SeanFT",
      external_link:
        "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/78841194175776325081494317283180106410278629423018831166144717576454623199233",
      asset_contract: "0x495f947276749Ce646f68AC8c248420045cb7b5e",
      owner: {
        address: "0x671e02b40d329eec50d26aa0e711341b28e73208",
        user: { username: "chrisfoste" },
        config: null,
      },
      mint_name: "OpenSea",
      mint_img: "http://librenfts.xyz/images/open-sea-logo.svg",
      traits: null,
      last_sale: null,
    };
    // const duplicatedAssets = duplicateAsset(fakeAssetObject);
    // setAssets((prevState) => [...prevState, ...duplicatedAssets]);
    setAssets((prevState) => [...prevState, fakeAssetObject]);
  };

 
  useEffect(() => {
    get_assets_without_api();
  }, []);

  return (
    <main>
      <header className="main-header">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://opensea.io/collection/libreplanet-groupchat-nfts"
        >
          <img
            className="header-avatar"
            src={librePlanetGroupChatPhoto}
            alt=""
          />
        </a>
        <h1>
          LIBREPLANET GROUPCHAT <br></br> NFTS
        </h1>
      </header>
      <ul className="nft-cards">
        {assets.map((asset) => (
          <NFTCard asset={asset} key={asset.token_id} />
        ))}
      </ul>
      <footer className="main-footer">
        <nav>
          <a href="/">Home of the LibrePlanet GroupChat NFTS</a>
        </nav>
      </footer>
    </main>
  );
}

export default App;
