import React from "react";
import clock from "../imgs/icons/icon-clock.svg";
import ethLogo from "../imgs/icons/icon-ethereum.svg";
import { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { usePopper } from "react-popper";

function NFTCard({ asset }) {
  const {
    name,
    description,
    asset_contract,
    token_id,
    image_url,
    mint_name,
    external_link,
    mint_img,
  } = asset;

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const instance = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
    placement: "top-start",
  });
  const { styles, attributes } = instance;

  const [showPopper, setShowPopper] = useState(false);

  const copyText = (e) => {
    navigator.clipboard
      .writeText(e.target.innerText)
      .then(() => {
        setShowPopper(true);
        instance.update();
        setTimeout(() => {
          setShowPopper(false);
        }, 1500);
      })
      .catch((err) => {
        alert("Error in copying text: ", err);
      });
  };

  const startTimeLeft = () => {
    // Set the date we're counting down to
    const countDownDate = new Date("Mar 29, 2022 22:00:00").getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      setTimeLeft(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setTimeLeft("EXPIRED");
      }
    }, 1000);
  };

  useEffect(() => {
    startTimeLeft();
  }, []);

  return (
    <div className="nft-card">
      <img src={image_url} alt="" className="nft-img" />
      <h2 className="nft-title">{name}</h2>
      <div className="nft-description">
        {description}
        <div className="contract-info">
          Contract Address:
          <span className="nft-contract-address overflow-elillipsis">
            <a
              target="_blank"
              rel="noreferrer"
              className="white"
              href={"https://etherscan.io/address/" + asset_contract}
            >
              {asset_contract}
            </a>
          </span>
        </div>
        <div className="token-info">
          Token ID:
          <span
            className="token-id overflow-elillipsis copy-click"
            ref={setReferenceElement}
            onClick={copyText}
            aria-describedby="tooltip"
          >
            {token_id}
          </span>
          <div
            ref={setPopperElement}
            style={styles.popper}
            className={showPopper ? "tooltip" : "d-hide"}
            {...attributes.popper}
          >
            Copied!
            <div ref={setArrowElement} style={styles.arrow} />
          </div>
        </div>
      </div>
      <div className="nft-card-footer">
        <div className="nft-info">
          <div className="price-container">
            <img src={ethLogo} alt="" />
            <a
              data-tip
              data-for="React-tooltip"
              href={external_link}
              target="_blank"
              rel="noreferrer"
            >
              <span> ??? ETH</span>
            </a>
            <ReactTooltip
              id="React-tooltip"
              place="top"
              type="dark"
              effect="float"
            >
              <span>Check for latest bid at OpenSea</span>
            </ReactTooltip>
          </div>
          <div className="date-container">
            <img src={clock} alt="" />
            <span>{timeLeft}</span>
          </div>
        </div>
        <hr className="soft-blue" />
        <div className="owner-info">
          <img src={mint_img} alt="" className="avatar-img" />
          <span>
            Minted at
            <span className="author-name">
              <a
                className="white no-underline"
                href={external_link}
                target="_blank"
                rel="noreferrer"
              >
                {" " + mint_name}
              </a>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default NFTCard;
